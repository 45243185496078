import React from "react"
import { navigate } from "gatsby-link"
import Layout from "../components/layout"
import Seo from "../components/seo"
import imgTitleKontakt from "../images/page_title_kontakt.png"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Contact() {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <Layout>
      <Seo title="kontakt" />
      <section className="contact container">
        <img className="page-title" src={imgTitleKontakt} alt="kontakt" />
        <p className="content-center">
          <strong>reservierungen</strong> telefon 05121 98910-40 oder bei uns in
          der kaffeebar
        </p>
        <form
          name="contact"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <div className="formgrid">
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Bitte nicht ausfüllen:
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <p>
              <label>
                nachname
                <br />
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  required
                />
              </label>
            </p>
            <p>
              <label>
                vorname
                <br />
                <input
                  type="text"
                  name="vorname"
                  onChange={handleChange}
                  required
                />
              </label>
            </p>
            <p>
              <label>
                e-mail:
                <br />
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  required
                />
              </label>
            </p>
            <p>
              <label>
                telefon
                <br />
                <input type="number" name="telefon" onChange={handleChange} />
              </label>
            </p>
          </div>
          <p className="formmessage">
            <label>
              nachricht an uns:
              <br />
              <textarea name="nachricht" onChange={handleChange} required />
            </label>
          </p>
          <p>
            <input
              type="checkbox"
              name="verstanden"
              value="verstanden"
              required
            />
            <strong>
              &nbsp;mir ist bewusst, dass ich online keine reservierungen vornehmen
              kann.
            </strong>
          </p>
          <p>
            <button type="submit">nachricht senden</button>
          </p>
        </form>
      </section>
    </Layout>
  )
}
